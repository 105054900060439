import React from "react";
import Landingfile from "./Landing/Landingfile";
import "../CSS/Projects.css";
import { Link } from "react-router-dom";

function Landingpage() {
  return (
    <div id="projects">
      <h2 align="center" className="headtitle1" id="projects">
        Landing Page Projects 
      </h2>
      <div className="container">
    
        <div className="row">
          {Landingfile.map((project) => {
            return (
              <div key={project.Oid} className="col-sm-6">
                <div className="position-relative project">
                  <img
                    className="imgpro1"
                    src={project.image}
                    alt={project.title}
                  />

                  <div className="project-des">
                    <Link to={project.link}>
                      <button className="btn btn-primary butdemo">Demo</button>
                    </Link>
                  </div>
                </div>
                <br />
                <br />
              </div>
            );
          })}
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Landingpage;
