import "../../CSS/lp2.css"
import AnimatedShapes from './Animatedshapes';
import { Link } from 'react-router-dom';

const Landingpage4 = () => {
  return (
    <>
      <div className="totalcontainer">
        <AnimatedShapes />
        <div className="totalcircle totalcircle2" />
        <div className="totalcard">
          <h1 className="totaltitle">
            <span className="totalbrandName">Demo</span> Landing Page
          </h1>
          <p className="totaldesc" data-aos="fade-right">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <Link to='/projects/landingpage/3'> <button className="totalbutton">Go</button></Link>
        </div>
        <div className="totalcard">
          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_rUsTCATHgu.json"
            background="transparent"
            speed="1"
            style={{ width: "350px", height: "350px" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </>
  );
};

export default Landingpage4;
