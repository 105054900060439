import React from "react";
import "../../CSS/lp5.css";
import { Link } from "react-router-dom";
function Landingpage5() {
  return (
    <div className="parent">
      <div className="h-100">
        <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_SkhtL8.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>

      <div className="textpart">
        <h1 className="textparth1">DEMO</h1>
        <h3 className="textparth3">Landing Page</h3>
        <Link to="/projects/landingpage/1">
          <button className="btn btn-danger">Get Started</button>
        </Link>
      </div>
    </div>
  );
}

export default Landingpage5;
