import React from "react";
import "../../CSS/animatedshapes.css"

const AnimatedShapes = () => {
  return (
    <>
      <div className="square"></div>
      <div className="circle"></div>
      <div className="rect"></div>

    </>
  );
};

export default AnimatedShapes;