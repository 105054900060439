import React from "react";
import Projectfile from "./Projectfile";
import "../CSS/Projects.css";

import { Link } from "react-router-dom";

function Projects() {
  return (
    <div id="projects">
      <h2 align="center" className="headtitle1" id="projects">
        React JS Projects
      </h2>
      <div className="container">
     <div className="row">
          {Projectfile.map((project) => {
            return (
              <div key={project.Oid} className="col-sm-6">
                <div className="position-relative project">
                  <img
                    className="imgpro1"
                    src={project.image}
                    alt={project.title}
                  />

                  <div className="project-des">
                    <Link to={project.link}>
                      <button className="btn btn-primary butdemo">Demo</button>
                    </Link>
                  </div>
                </div>
                <br />
                <br />
              </div>
            );
          })}
        </div>
    
      <h2 align="center" className="headtitle1" id="projects">MERN stack Projects</h2>
        <div className="row ">
          <div className="col-sm-6">
            <div className="position-relative project">
              <img
                className="imgpro1"
                src="./ecommerce.jpg"
                alt="Ecommerce App thumbnail" 
              />  

              <div className="project-des">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://sudip-ecommerce.cyclic.app/"
                >
                  <button className="btn btn-primary ">Demo</button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="position-relative project">
              <img
                className="imgpro1"
                src="./facebook.jpg"
                alt="Facebook thumbnail"
              />

              <div className="project-des">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://sudip-facebook.cyclic.app/"
                >
                  <button className="btn btn-primary butdemo">Demo</button>
                </a>
              </div>
            </div>
          </div>
        </div>
       
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default Projects;
