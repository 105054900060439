const Projectfile = [
  {
    Oid: 1,
    title: "Calculator",
    image: "./calculator.jpg",
    description: "A Caculator App developed using React and Bootstrap",
    link: "/projects/calculator",
  },

  {
    Oid: 2,
    title: "Password Generator",
    image: "./passgen.jpg",
    description: "A Random Pasword Generator app",
    link: "/projects/random-password-generator",
  },
  {
    Oid: 5,
    title: "Landing Page",
    image: "./landingpage.jpg",
    description: "A Landing Page app",
    link: "/projects/landingpage",
  },

  {
    Oid: 4,
    title: "Photo Gallery",
    image: "./photogallery.jpg",
    description: "A PhotoGallery app",
    link: "/projects/photogallery",
  },
 

  
];

export default Projectfile;
