import React from "react";
import "../CSS/service.css";

function Services() {
  return (
    <div>
      <div id="services" className="container-fluid">
        <div className="servicevh1">
          <h3 align="center" className="servicetitle1">
            Professsional Skills
          </h3>

          <div className="row">
            <div className="col-12  col-sm-6 p-2">
              <div className="card bgcard2">
                <div className="lote1 mt-2 ">
                  <lottie-player
                    src="https://assets6.lottiefiles.com/packages/lf20_czuvt9mm.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
                <div className="card-body listskill1">
                  <h5 className="card-title">Responsive Web Design</h5>
                  <ul>
                    <li>CSS</li>
                    <li>HTML</li>
                    <li>Bootstrap</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12  col-sm-6 p-2">
              <div className="card bgcard2">
                <div className="lote1">
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_aptscmnx.json"
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></lottie-player>
                </div>
                <div className="card-body listskill1">
                  <h5 className="card-title">Front End Development</h5>
                  <ul>
                    <li>React JS</li>
                    <li>Next JS</li>
                    <li>Javascript</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-12  col-sm-6 p-2">
              <div className="card bgcard2">
                <div className="lote1">
                  <lottie-player
                    src="https://assets5.lottiefiles.com/packages/lf20_iv4dsx3q.json"
                    background="transparent"
                    speed="1"
                    autoplay
                    loop
                  ></lottie-player>
                </div>
                <div className="card-body listskill1">
                  <br />
                  <h5 className="card-title">
                    <span
                      style={{
                        backgroundColor: "#171452",
                        color: "white",
                        padding: "4px",
                      }}
                    >
                      Speed Coding
                    </span>
                  </h5>
                  <ul>
                    <br />
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12  col-sm-6 p-2">
              <div className="card bgcard2">
                <div className="lote1  mt-2">
                 <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_2nbubmkc.json"  background="transparent"  speed="1"   loop  autoplay></lottie-player>
                </div>
                <div className="card-body listskill1">
                  <h5 className="card-title">Graphic Design</h5>
                  <ul>
                    <li>Photoshop</li>
                    <li>Illustrator</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
