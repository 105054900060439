import React, { useEffect } from "react";
import "../CSS/photo.css";

import { Parallax, Background } from "react-parallax";

function Photo() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="container-fluid backgra1">
        <div className="row ">
          <Parallax strength={500}>
            <Background>
              <img className="parallaximg1" src="/ph1.png" alt="parallaxbg" />
            </Background>
            <div className="parallax-content flex-with-center ">
              <div data-aos="fade-right">
                <h1>Sudip</h1>
                <div className="intro-content justify-content-center d-flex">
                  <p className="firsttitle1">&nbsp;Front End Developer&nbsp;</p>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        <br />
        <h3 align="center" className="pg1">
          Photoshop Art Gallery
        </h3>

        <div className="row">
          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph1.png">
                <img
                  src="/ph1.png"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 1</p>
            </div>
          </div>

          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph2.jpg">
                <img
                  src="/ph2.jpg"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 2</p>
            </div>
          </div>

          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph3.png">
                <img
                  src="/ph3.png"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 3</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph4.png">
                <img
                  src="/ph4.png"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 4</p>
            </div>
          </div>

          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph5.jpg">
                <img
                  src="/ph5.jpg"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 5</p>
            </div>
          </div>

          <div className="col-sm-4  p-2">
            <div className="cbox1">
              <a target="_blank" href="/ph6.jpg">
                <img
                  src="/ph6.jpg"
                  className="  img-fluid imagea"
                  alt="Photoshop IMG"
                ></img>
              </a>
              <p className="lettera">Photoshop Art 6</p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default Photo;
