import { Route, Routes } from "react-router-dom";
import Calculator from "./components/Calculator";
import Projects from "./components/Projects";
import AOS from "aos";
import "aos/dist/aos.css";
import Homepage from "./components/Homepage";
import Photo from "./components/Photo";
import Passgen from "./components/Passgen";
import Landingpage from './components/Landingpage';
import Landingpage1 from './components/Landing/Landingpage1';
import Landingpage2 from './components/Landing/Landingpage2';
import Landingpage3 from './components/Landing/Landingpage3';
import Landingpage4 from "./components/Landing/Landingpage4";
import Landingpage5 from "./components/Landing/Landingpage5";

AOS.init({
  duration: 2000,
});

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/calculator" element={<Calculator />} />
        <Route path="/projects/photogallery" element={<Photo />} />
        <Route path="/projects/random-password-generator" element={<Passgen />}/>
      <Route path="/projects/landingpage" element={<Landingpage/>}/>
      <Route path="/projects/landingpage/1" element={<Landingpage1/>}/>
      <Route path="/projects/landingpage/2" element={<Landingpage2/>}/>
      <Route path="/projects/landingpage/3" element={<Landingpage3/>}/>
      <Route path="/projects/landingpage/4" element={<Landingpage4/>}/>
      <Route path="/projects/landingpage/5" element={<Landingpage5/>}/>


      </Routes>

    </div>
  );
}

export default App;
