import React from "react";
import "../CSS/footer.css";

function Footer() {
  return (
    <div>
      <div className="footer-bottom text-center">
        <div className="container ">
          <div className="row justify-content-center ">
            <div className="footer-site-info">
              &nbsp; &nbsp; 2023 ©{" "}
              <a href="https://www.upwork.com/freelancers/~0129cc190c42ac4a7b"  style={{ textDecoration: "none" }}>
                Sudip Tasi
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
