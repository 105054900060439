import React from "react";
import "../CSS/contact.css";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { SiUpwork } from "react-icons/si";

function Contact() {
  return (
    <div id="contactus" className="container-fluid">
      <div className="row justify-content-center mt-5 contactheight ">
        <div className="col-sm-6 " data-aos="fade-right">
          <div className="card bgcontact2 text-center mb-5 ">
            <h2 className="mt-3" align="center">
              Get in Touch with Us
            </h2>
            <div className="card-body justify-content-center">
              <h5 className="card-title">
                <FaLinkedin size={30} />
              </h5>
              <p className="card-text">
                LinkedIn:{" "}
                <a
                  className="contacthref1"
                  href="https://www.linkedin.com/in/sudip-tasi/"
                >
                  Sudip Tasi
                </a>
              </p>
            </div>
            <div className="card-body justify-content-center">
              <h5 className="card-title">
                <FaGithub size={30} />
              </h5>
              <p className="card-text">
                Github:
                <a className="contacthref1" href="https://github.com/Ace-Sudip">Ace-Sudip</a>
              </p>
            </div>
            <div className="card-body justify-content-center">
              <h5 className="card-title">
                <SiUpwork size={30} />
              </h5>
              <p className="card-text">
                Upwork:{" "}
                <a
                  className="contacthref1"
                  href="https://www.upwork.com/freelancers/~0129cc190c42ac4a7b"
                >
                  Sudip Tasi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
