const Landingfile = [
    {
      Oid: 1,
      title: "Calculator",
      image: "/lp1.jpg",
      link: "/projects/landingpage/1",
    },
  
    {
      Oid: 2,
      title: "Password Generator",
      image: "/lp2.jpg",
      link: "/projects/landingpage/2",
    },
  
    {
        Oid: 3,
        title: "Todos-List",
        image: "/lp3.jpg",
        link: "/projects/landingpage/3",
    },
  
    {
      Oid: 4,
      title: "Photo Gallery",
      image: "/lp4.jpg",
      link: "/projects/landingpage/4",
    },
    {
      Oid: 5,
      title: "Landing Page",
      image: "/lp5.jpg",
      link: "/projects/landingpage/5",
    },
  
    
  ];
  
  export default Landingfile;