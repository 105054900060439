import React, { useState } from "react";
import { CgMenuRightAlt, CgClose } from "react-icons/cg";
import "../CSS/header.css";

function Header() {
  const [showHeader, setshowHeader] = useState(false);

  function closeHeader() {
    setshowHeader(false);
  }
  return (
    <div className="header">
      {showHeader ? (
        <CgClose
          onClick={() => setshowHeader(!showHeader)}
          className="menu-icon position-fixed"
        />
      ) : (
        <CgMenuRightAlt
          onClick={() => setshowHeader(!showHeader)}
          className="menu-icon position-fixed"
        />
      )}
      <ul className={`${showHeader ? "show-header" : "hide-header"} n-box1`}>
        <li onClick={closeHeader}>
          <a href="#home">Home</a>
        </li>
        <li onClick={closeHeader}>
          <a href="#projects">Projects</a>
        </li>
        <li onClick={closeHeader}>
          <a href="#services">Services</a>
        </li>
        <li onClick={closeHeader}>
          <a href="#contactus">Contact</a>
        </li>
      </ul>
    </div>
  );
}

export default Header;
