import React from "react";
import "../CSS/home.css";

function Home() {
  return (
    <div id="home">
      <div className="container-fluid ">
        <div className="row vhwid justify-content-center align-items-center ">
          <div data-aos="fade-right">
            <div className="card-container">
              <img
                className="round mt-3"
                src="profile2.jpg"
                alt="Sudip Profile"
              />

              <div className="i-title d-flex justify-content-center">
                <div className="i-title-wrapper">
                  <div className="i-title-item">Hi there,</div>
                  <div className="i-title-item">I am</div>
                  <div className="i-title-item">Sudip Tasi</div>
                </div>
              </div>
              <h6 className="name2">Front End React Developer</h6>
              <div className="buttons2">
                <a href="#projects">
                  <button className="primary ">Projects</button>
                </a>
                <a href="#services">
                  <button className="primary ">Services</button>
                </a>
              </div>
              <div className="skills">
                <h6>Skills</h6>
                <ul>
                  <li style={{ backgroundColor: "#04a2bf " }}>React js</li>
                  <li style={{ backgroundColor: "#EAD41C" }}>JavaScript</li>
                  <li style={{ backgroundColor: "black" }}>Next js</li>
                  <li style={{ backgroundColor: "#7248B6" }}>Redux</li>
                  <li style={{ backgroundColor: "#E44C30" }}>Git</li>
                  <li style={{ backgroundColor: "#533979" }}>Bootstrap</li>
                  <li style={{ backgroundColor: "#146CAE" }}>CSS</li>
                  <li style={{ backgroundColor: "#169c92" }}>Chakra UI</li>
                  <li style={{ backgroundColor: "#D84924" }}>HTML</li>
                  <li style={{ backgroundColor: "#190428" }}>Web design</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <br />
    </div>
  );
}

export default Home;
