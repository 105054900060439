import React from "react";
import { Link } from "react-router-dom";
import "../../CSS/lp4.css"

function Landingpage4() {
  return (
    <div className="rotatedparent">
      <div className="rotatedrow">
        <div className="row h-100 align-items-center">
          <div className="col-md-6">
            <div className="h-75 p-5">
              <lottie-player
                src="https://assets10.lottiefiles.com/private_files/lf30_eaeadwwn.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
          <div className="col-md-6">
            <div className="rotatedtextpart">
              <h1>
                <b>DEMO</b>
              </h1>
              <p className="rotatedparentp">
                <strong>Landing Page</strong>
              </p>
             <Link to="/projects/landingpage/5"><button className="btn btn-danger">Get Started</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingpage4;
